<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-if="clusterId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Tedarikçi Tanımları"
            icon="mdi-mower"
            :add-route="
              can('edit-cluster-provider')
                ? { name: 'definitions.cluster-providers.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-cluster-provider')"
            :edit-enabled="selectedItems.length === 1"
            :delete-enabled="deleteEnabled"
            @click:delete="handleBatchDeleteClick"
            :search.sync="search.query"
            :show-delete="can('delete-cluster-provider')"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:append">
              <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                :disabled="selectedItems.length !== 1"
                :to="
                  selectedItems.length === 1 && {
                    name: 'expenses.invoice.create',
                    query: { provider_id: selectedItems[0].id },
                  }
                "
                v-if="can('edit-invoice')"
              >
                Fatura Ekle
              </v-btn>

              <rs-action
                css-class="float-end ms-2"
                v-if="hasRole('superadministrator')"
                @click="handleImportClick"
                :disabled="!clusterId"
              >
                İçeri Aktar
              </rs-action>

              <rs-file
                label="Dosya"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                v-if="!disabled"
                @change="handleFileChange"
                class="d-none"
                id="importInput"
              />
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'definitions.cluster-providers.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'definitions.cluster-providers.show',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.auto_assessment="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.debt="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.due="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.diff="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <rs-table-button-delete
            @deleted="handleDeleteConfirmed(item)"
            :loading="isLoading"
            :disabled="!!(item.due || item.debt)"
            confirmed
          />
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters([
      "cluster",
      "clusterId",
      "fullExpenseTypeList",
      "providerTypeList",
    ]),
    deleteEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].due || this.selectedItems[index].debt) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-cluster-provider"),
        },
        {
          text: this.$t("labels.provider_name"),
          value: "name",
          searchable: "text",
          sortable: true,
        },
        {
          text: this.$t("labels.provider_type"),
          value: "provider_type",
          searchable: "select",
          sortable: true,
          options: () => this.providerTypeList,
          itemText: "name",
        },
        {
          text: "Gelir/Gider Türü",
          value: "expense_type",
          searchable: "select",
          options: () => this.fullExpenseTypeList,
          sortable: true,
        },
        {
          text: this.$t("labels.debt"),
          value: "debt",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.due"),
          value: "due",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.balance"),
          value: "diff",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.auto_assessment"),
          value: "auto_assessment",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: null,
          value: "actions",
          searchable: false,
          sortable: false,
          align: "right",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `cluster-providers`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = this.getParams();

      this.$api
        .query(`cluster-providers`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "definitions.cluster-providers.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane tedarikçiyi silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed(itemOrEvent) {
      if (this.isLoading) return false;

      let itemIds;

      if (itemOrEvent?.id) {
        itemIds = [itemOrEvent.id];
      } else {
        itemIds = this.selectedItems.map((item) => item.id);
      }

      this.isLoading = true;
      const promises = [];

      for (const index in itemIds) {
        const promise = this.$api.delete(`cluster-providers/${itemIds[index]}`);
        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Seçilen kayıtlar silindi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.loadList();
        });
    },
    handleImportClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file || !this.clusterId) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);
      data.append("cluster_id", this.clusterId);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(`cluster-providers/import`, data, config)
        .then((response) => {
          this.$toast.success(
            `${response.data.data.count} adet tedarikçi kaydedildi`
          );

          setTimeout(() => this.loadList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
