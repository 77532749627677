var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.isLoading}},[(_vm.clusterId)?_c('v-card-text',[(_vm.clusterId)?_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Tedarikçi Tanımları","icon":"mdi-mower","add-route":_vm.can('edit-cluster-provider')
              ? { name: 'definitions.cluster-providers.create' }
              : null,"hide-edit":!_vm.can('edit-cluster-provider'),"edit-enabled":_vm.selectedItems.length === 1,"delete-enabled":_vm.deleteEnabled,"search":_vm.search.query,"show-delete":_vm.can('delete-cluster-provider')},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"click:delete":_vm.handleBatchDeleteClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false),[_c('template',{slot:"buttons:append"},[(_vm.can('edit-invoice'))?_c('v-btn',{staticClass:"float-end ms-2",attrs:{"color":"pink","outlined":"","small":"","disabled":_vm.selectedItems.length !== 1,"to":_vm.selectedItems.length === 1 && {
                  name: 'expenses.invoice.create',
                  query: { provider_id: _vm.selectedItems[0].id },
                }}},[_vm._v(" Fatura Ekle ")]):_vm._e(),(_vm.hasRole('superadministrator'))?_c('rs-action',{attrs:{"css-class":"float-end ms-2","disabled":!_vm.clusterId},on:{"click":_vm.handleImportClick}},[_vm._v(" İçeri Aktar ")]):_vm._e(),(!_vm.disabled)?_c('rs-file',{staticClass:"d-none",attrs:{"label":"Dosya","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet","id":"importInput"},on:{"change":_vm.handleFileChange}}):_vm._e()],1)],2):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [(_vm.list.length > 0)?_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}}):_vm._e()]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'definitions.cluster-providers.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'definitions.cluster-providers.show',
            params: { id: item.id },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.auto_assessment",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.is_active",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.debt",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.due",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.diff",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.actions",fn:function({ item }){return [_c('rs-table-button-delete',{attrs:{"loading":_vm.isLoading,"disabled":!!(item.due || item.debt),"confirmed":""},on:{"deleted":function($event){return _vm.handleDeleteConfirmed(item)}}})]}}],null,false,599101417),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false)):_vm._e(),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDeleteConfirmed,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }